import $ from 'jquery'
const $doc = $(document)
const popup = $(".site-popup");

function showPopup() {
    if (popup.length > 0 && !sessionStorage.getItem('popupShown')) {
        popup.show().trigger('focus');
        $(".main, .header, .footer, aside").attr('inert', '');
        sessionStorage.setItem('popupShown', 'true');
    }
};

function hidePopup() {
    if (popup.length > 0) {
        popup.fadeOut(300);
        $(".main, .header, .footer, aside").removeAttr("inert");
    }
}

export default function initPopup() {
    $doc.ready(showPopup);
    $doc.on('click', '.site-popup__button', hidePopup)
    $doc.on('click', function(event) {
        if (!$(event.target).closest('.site-popup__box').length) {
            hidePopup();
        }           
    });
}